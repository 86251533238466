<template>
  <div v-if="template" class="grid-x grid-padding-x">
    <div class="cell large-4 medium-12 small-12">
      <md-field :class="getValidationClass('name')">
        <label for="template-name">Name</label>
        <md-input name="template-name" id="template-name" autocomplete="off" placeholder="Name der Vorlage"
                  v-model="template.name" :disabled="sending"/>
        <span class="md-error"
              v-if="!$v.template.name.required">Name ist notwendig</span>
      </md-field>
    </div>

    <div class="cell large-4 medium-6 small-6">
      <md-field :class="getValidationClass('type')">
        <label>Typ</label>
        <md-select v-model="template.type" autocomplete="off" :disabled="sending">
          <md-option v-for="templateType of selectableTemplateTypes" v-bind:value="templateType" :key="templateType" v-if="templateType !== 'BLANK'">
            {{labels.templateType[templateType]}}
          </md-option>
        </md-select>
        <span class="md-error" v-if="!$v.template.type.required">Typ ist notwendig</span>
      </md-field>
    </div>

    <div class="cell large-4 medium-6 small-6">
      <br>
      <md-checkbox class="md-primary" v-model="template.active">
        <span style="font-size: 1rem">Als <b>aktive</b> Vorlage verwenden.</span>
      </md-checkbox>
    </div>
  </div>
</template>

<script>
  import {validationMixin} from 'vuelidate';
  import {
    required,
  } from 'vuelidate/lib/validators';

  export default {
    name: 'TemplateForm',
    mixins: [validationMixin],

    props: {
      template: {
        required: true
      },
      mode: {
        required: true
      },
      sending: {
        required: true
      },
    },

    updated() {
      if (this.mode !== 'add') {
        this.validateTemplate();
      }
    },

    data: () => ({

      invalid: true,

      fromDate: null,
      updateFromDateAllowed: true,

      untilDate: null,
      updateUntilDateAllowed: true,

      templateIntervals: [],
    }),

    validations: {
      template: {
        name: {
          required,
        },
        type: {
          required,
        },
      },
    },

    methods: {

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      getValidationClass(fieldName) {
        let field = this.$v.template[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateTemplate() {
        this.$v.$touch();
        this.invalid = this.$v.$invalid;
      },
    },

    computed: {
      labels() {
        return this.$store.getters.labels;
      },

      selectableTemplateTypes() {
        return this.$store.getters.labels.templateTypes.filter(obj => obj !== 'EXAMPLE');
      },
    },
  };
</script>

<style lang="scss" scoped>
  h5 {
    font-weight: 300;
  }

  .md-tooltip {
    font-size: medium;
    white-space: normal;
  }

  [type=button] {
    border-radius: 50%;
  }
</style>

