<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.baseModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3>
            <md-icon style="color: black; font-size: 2rem!important; padding-left: 1rem;; padding-right: 1rem;">
              print
            </md-icon>&nbsp;&nbsp;&nbsp;Druckvorlagen
          </h3>
        </div>
      </div>
      <div @click="onTouchOverlay" id="overlayLeft" style="width: 50%; top: 17%; height: 82%;"></div>
      <div class="grid-x grid-padding-x grid-padding-y" v-if="facilitiesNotExists">
        <NoFacilityCard functionTitle="Druckvorlagen"></NoFacilityCard>
      </div>
      <div class="grid-x grid-padding-x grid-padding-y" v-else>
        <div class="cell medium-5">
          <md-card>
            <md-card-header>
              <md-field v-if="!isSage && availableFacilities && availableFacilities.length > 0">
                  <label>Einrichtung</label>
                  <md-select :disabled="sending || availableFacilities.length <= 1"
                             @md-selected="onSelectFacility"
                             v-model="selectedFacilityReferenceNumber">
                    <md-option :key="facility.referenceNumber"
                               :value="facility.referenceNumber"
                               v-for="facility of availableFacilities">
                      {{ facility.longName }}
                    </md-option>
                  </md-select>
                </md-field>
            </md-card-header>
            <md-card-content>
              <md-tabs md-elevation="1">
                <md-tab md-label="Druckvorlagen">
                  <div class="cell" style="height: 1102px; margin-top: 2px;">
                    <div style="padding: 1px;">
                      <md-table id="template-table-id" @md-selected="onSelect" md-fixed-header md-card
                                md-sort="name" md-sort-order="asc" v-model="searched" style="height: 1099px;">
                        <md-table-toolbar>
                          <div class="md-toolbar-section-start grid-x grid-padding-x">
                            <div class="cell large-10 medium-10 small-10">
                              <br>
                              <p class="md-title ai-number" style="margin-left: 0;">{{searched.length}} Druckvorlage<span
                                  v-if="searched .length !== 1">n</span> <span
                                  class="hide-for-medium-only hide-for-small-only">verfügbar</span></p>
                              <br>
                            </div>
                            <div class="cell large-2 medium-2 small-2">
                              <div v-if="sending">
                                <vue-simple-spinner></vue-simple-spinner>
                              </div>
                            </div>
                            <div class="cell large-12 medium-12 small-12">
                              <md-field class="md-toolbar-section-end" md-clearable>
                                <md-input @input="searchOnTable" placeholder="Suche Druckvorlage Name ..."
                                          v-model="search"/>
                              </md-field>
                            </div>

                            <div class="cell" style="padding-bottom: 15px;">
                              <md-button v-if="(isAdmin || isFacilityAdmin || isTreasurer || isManagement) && canWrite"
                                         @click="onAddTemplate"
                                         class="md-icon-button md-raised md-primary"
                                         :disabled="sending">
                                <md-icon>add</md-icon>
                                <md-tooltip>Neue Druckvorlage hinzufügen</md-tooltip>
                              </md-button>
                              <md-button v-if="(isAdmin || isFacilityAdmin || isTreasurer || isManagement) && canWrite"
                                         @click="onDeleteTemplate"
                                         class="md-icon-button md-raised md-accent"
                                         :disabled="!selected || sending" >
                                <md-icon>delete</md-icon>
                                <md-tooltip>Druckvorlage löschen</md-tooltip>
                              </md-button>
                            </div>
                          </div>
                        </md-table-toolbar>

                        <md-table-row :class="getClass(item.id)" :id="item.id" md-selectable="single" slot="md-table-row"
                                      slot-scope="{ item }">
                          <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}
                          </md-table-cell>
                          <md-table-cell md-label="Typ" md-sort-by="type"><i>{{ labels.templateType[item.type] }}</i></md-table-cell>
                          <md-table-cell md-label="aktiv">
                      <span v-if="item.active">
                        <md-icon style="color: white" v-if="selectedTemplate && item.id === selectedTemplate.id">check_circle_outline</md-icon>
                        <md-icon style="color: forestgreen" v-else>check_circle_outline</md-icon>
                      </span>
                          </md-table-cell>
                        </md-table-row>

                      </md-table>
                    </div>
                  </div>
                </md-tab>
                <md-tab md-label="Briefpapier">
                  <div class="cell" style="height: 1102px; margin-top: 2px;">
                    <div v-if="selectedFacility" class="md-toolbar md-transparent md-theme-default">
                      <div class="grid-x grid-padding-y" style="padding-top: 20px;">
                        <div class="cell small-12">
                          <p class="md-title" style="font-size: 20px;">Briefpapier
                            <span v-if="this.selectedFacility" class="hide-for-medium-only">für <b>{{this.selectedFacility.name}}</b></span>
                          </p>
                        </div>
                        <div class="cell small-12">
                          <div class="letter-paper-container">
                            <md-content class="letter-paper md-elevation-1">
                              <div v-if="selectedFacility.facilitySettings.letterPaperFirstId">
                                <pdf v-if="firstLetterPaperPdf.url" :page="1" :resize="false" :scale.sync="0.26" :src="firstLetterPaperPdf.url" class="pdf-frame"></pdf>
                              </div>
                              <div v-else class="text-center">
                                <span v-if="letterPaperSending && selectedLetterPaperPage === 0">
                                  <vue-simple-spinner></vue-simple-spinner>
                                </span>
                                <span v-else>
                                  Seite 1<br><br>
                                  Kein Briefpapier ausgewählt
                                </span>
                              </div>
                            </md-content>
                            <md-content class="letter-paper-button md-elevation-0">
                              <div class="grid-x">
                                <div class="cell small-8">
                                  <div @click="onVdropzoneClick(0)" style="margin-right: 10px;">
                                    <vue-dropzone :disabled="letterPaperSending || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite" :options="optionsPageOne"
                                                  @vdropzone-error="onVdropzoneError"
                                                  @vdropzone-mounted="onVdropzoneMounted"
                                                  @vdropzone-processing="onVdropzoneProcessing"
                                                  @vdropzone-success="onVdropzoneSuccess"
                                                  id="letterPaperFileDropzonePage1"
                                                  ref="vueDropzoneRef"
                                                  v-if="optionsPageOne.url">
                                    </vue-dropzone>
                                    <md-tooltip>Hier können Sie ihr eigenes Briefpapier für Seite 1 ihrer Dokumente auswählen und im
                                      System für diese Einrichtung hinterlegen.
                                    </md-tooltip>
                                  </div>
                                </div>
                                <div class="cell small-4 text-center">
                                  <md-button class="md-icon-button md-raised md-accent" style="margin-right: 0;" @click="onDeleteLetterPaper(0)"
                                             :disabled="!selectedFacility.facilitySettings.letterPaperFirstId || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite">
                                    <md-icon>delete</md-icon>
                                    <md-tooltip>Briefpapier für Seite 1 löschen</md-tooltip>
                                  </md-button>
                                </div>
                                <div class="cell small-12">
                                  <md-checkbox class="md-primary" v-model="selectedFacility.facilitySettings.useLetterPaperFirstForAllPages"
                                               :disabled="sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite" @change="onToggleFirstForAll">
                                    <span style="font-size: 1rem">Für alle Seiten</span>
                                    <md-tooltip>Briefpapier für Seite 1 auch für alle anderen Seiten verwenden.</md-tooltip>
                                  </md-checkbox>
                                </div>
                              </div>
                            </md-content>
                          </div>
                        </div>
                        <div class="cell small-12">
                          <div class="letter-paper-container">
                            <md-content class="letter-paper md-elevation-1">
                              <div v-if="selectedFacility.facilitySettings.letterPaperSecondId">
                                <pdf v-if="secondLetterPaperPdf.url" :page="1" :resize="false" :scale.sync="0.26" :src="secondLetterPaperPdf.url" class="pdf-frame"></pdf>
                              </div>
                              <div v-else class="text-center">
                                <span v-if="letterPaperSending && selectedLetterPaperPage === 1">
                                  <vue-simple-spinner></vue-simple-spinner>
                                </span>
                                <span v-else>Seite 2 &amp; folgende<br><br>Kein Briefpapier ausgewählt</span>
                              </div>
                            </md-content>
                            <md-content class="letter-paper-button md-elevation-0" style="width: 100%;">
                              <div class="grid-x" style="width: 100%;">
                                <div class="cell small-8">
                                  <div @click="onVdropzoneClick(1)" style="margin-right: 10px;">
                                    <vue-dropzone :disabled="sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite" :options="optionsPageTwo"
                                                  @vdropzone-error="onVdropzoneError"
                                                  @vdropzone-mounted="onVdropzoneMounted"
                                                  @vdropzone-processing="onVdropzoneProcessing"
                                                  @vdropzone-success="onVdropzoneSuccess"
                                                  id="letterPaperFileDropzonePage2"
                                                  ref="vueDropzoneRef"
                                                  v-if="optionsPageTwo.url">
                                    </vue-dropzone>
                                    <md-tooltip>Hier können Sie ihr eigenes Briefpapier für Seite 2 und folgende ihrer Dokumente auswählen und im
                                      System für diese Einrichtung hinterlegen.
                                    </md-tooltip>
                                  </div>
                                </div>
                                <div class="cell small-4 text-center">
                                  <md-button class="md-icon-button md-raised md-accent" style="margin-right: 0;" @click="onDeleteLetterPaper(1)"
                                             :disabled="!selectedFacility.facilitySettings.letterPaperSecondId || sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite">
                                    <md-icon>delete</md-icon>
                                    <md-tooltip>Briefpapier für Seite 2 und folgende löschen</md-tooltip>
                                  </md-button>
                                </div>
                              </div>
                            </md-content>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </md-tab>
              </md-tabs>
            </md-card-content>
          </md-card>
        </div>

        <div class="cell medium-7">
          <md-card id="editTemplateCardId" style="height: 1300px;">
            <md-card-header id="editTemplateCardHeaderId">
              <h4 style="font-size: 1.6rem; font-weight: 300">{{selectedFacilityName}}</h4>
              <h5 v-if="!selectedTemplate"><br>
                <p><i>Bitte wählen Sie eine Druckvorlage links zur Bearbeitung aus.</i></p></h5>

              <div class="grid-x grid-padding-x" v-else>
                <div class="cell large-4 medium-6 small-12">
                  <md-field :class="getValidationClass('name')">
                    <label>Name</label>
                    <md-input :disabled="sending || selectedTemplate.standard" autocomplete="off" id="template-name"
                              name="template-name" v-model="selectedTemplate.name"/>
                    <span class="md-error"
                          v-if="!$v.selectedTemplate.name.required">Name ist notwendig</span>
                  </md-field>
                </div>

                <div class="cell large-4 medium-6 small-12">
                  <md-field :class="getValidationClass('type')">
                    <label>Typ</label>
                    <md-select :disabled="sending || true" @md-selected="onSelectTemplateType"
                               autocomplete="off" v-model="selectedTemplate.type">
                      <md-option :key="templateType"
                                 v-bind:value="templateType"
                                 v-for="templateType of labels.templateTypes" v-if="templateType !== 'BLANK'">
                        {{labels.templateType[templateType]}}
                      </md-option>
                    </md-select>
                    <span class="md-error"
                          v-if="!$v.selectedTemplate.type.required">Typ ist notwendig</span>
                  </md-field>
                </div>

                <div class="cell large-4 medium-0 hide-for-small-only"></div>

                <div class="cell large-4 medium-6 small-12 text-center">
                  <br>
                  <md-checkbox v-if="!selectedTemplate.standard" class="md-primary" v-model="selectedTemplate.active"
                               :disabled="sending || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite">
                    <span style="font-size: 1rem"><b>Aktive</b> Druckvorlage</span>
                    <md-tooltip>Dieses Template als aktive Druckvorlage für alle Dokumente vom Typ <b>{{labels.templateType[selectedTemplate.type]}}</b> verwenden.</md-tooltip>
                  </md-checkbox>
                  <div v-else>
                    <p><i><b>Achtung</b> System-Druckvorlage!<br>Änderungen werden nicht gespeichert.</i></p>
                    <md-tooltip>Bitte eine neue Druckvorlage dieses Typs anlegen. Diese kann dann individuell angepasst werden.</md-tooltip>
                  </div>
                </div>

                <div class="cell large-4 medium-6 small-12">
                  <br>
                  <button :disabled="sending || pristine() || selectedTemplate.standard || !(isAdmin || isFacilityAdmin || isTreasurer || isManagement) || !canWrite"
                          @click="onSaveChanges"
                          class="button success expanded">
                    <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
                  </button>
                </div>

                <div class="cell large-4 medium-0 hide-for-small-only"></div>
              </div>

            </md-card-header>
            <md-card-content>
              <md-tabs v-if="selectedTemplate" @md-changed="onTabChanged">
                <md-tab id="tab-template-editor" md-label="Editor" style="max-height: 980px">
                  <div class="grid-x grid-padding-x" style="padding-top: 1rem;">
                    <div class="cell large-4 medium-6 small-6">
                      <md-field>
                        <label>Variable einfügen</label>
                        <md-select :disabled="sending" @md-selected="onInsertTemplateTag"
                                   id="template-tags" name="template-tags"
                                   v-model="selectedTemplateTag">
                          <md-option :key="tag.key"
                                     v-bind:value="tag.value.example"
                                     v-for="tag of filteredTemplateTags">
                            {{tag.value.name}}
                            <md-tooltip style="z-index: 999" md-direction="right"
                                        v-html="tag.value.tooltip"></md-tooltip>
                          </md-option>
                        </md-select>
                        <md-tooltip>Wählen Sie eine Variable aus der Liste und diese wird an der
                          aktuellen Position des Cursors im Text-Editor eingefügt.
                        </md-tooltip>
                      </md-field>
                    </div>
                  </div>
                  <vue-editor @focus="onFocusEditor" @selection-change="onSelectionChange"
                              @text-change="onTextChange" v-model="selectedTemplate.content"></vue-editor>
                </md-tab>
                <md-tab id="tab-template-preview" md-label="Vorschau" style="max-height: 1130px">
                  <div v-if="facilityChildren && facilityChildren.length > 0">
                    <div id="previewSettingsId" class="grid-x grid-padding-x"  style="padding-top: 1rem;">
                      <div class="cell large-4 medium-6">
                        <md-field>
                          <label>Vorschau für Kind</label>
                          <md-select :disabled="sending" @md-selected="updatePreviewChild"
                                     id="children"
                                     name="children"
                                     v-model="selectedPreviewChildId">
                            <md-option :key="child.id" v-bind:value="child.id"
                                       v-for="child of facilityChildren">
                              {{child.masterdata.fullName}}
                            </md-option>
                          </md-select>
                          <md-tooltip>Kind für die Vorschau des Dokuments auswählen</md-tooltip>
                        </md-field>
                      </div>
                      <div class="cell large-4 medium-6">
                        <div class="md-layout">
                          <div class="md-layout-item" style="padding-right: 15px;">
                            <md-datepicker id="preview-date-dp" :md-debounce="10000" :md-open-on-focus="false" :disabled="sending" @md-closed="updatePreviewChild"
                                           md-immediately :md-disabled-dates="disabledFirstDay" v-model="previewDate">
                                <md-tooltip>Stichtag für die Vorschau des Dokuments auswählen</md-tooltip>
                            </md-datepicker>
                          </div>
                        </div>
                      </div>
                      <div class="cell large-4 medium-0 small-0">
                        <br>
                        <md-checkbox class="md-primary" v-model="selectedTemplate.suppressUseOfLetterPaper" :disabled="sending" @change="updatePreviewChild">
                          <span style="font-size: 1rem">Ohne Briefpapier</span>
                          <md-tooltip>Das hinterlegte Briefpapier für Seite 1 / Seite 2 und folgende verwenden.</md-tooltip>
                        </md-checkbox>
                        <br>
                      </div>
                      <div v-if="pdfNumPages > 1" class="cell" style="padding-bottom: 1rem;">
                        <md-button v-for="i in pdfNumPages" :key="i" :id="i" @click="onSelectPdfPage(i)" class="md-icon-button">
                          <span v-if="pdfPage === i" style="color: cornflowerblue; font-size: 1.2rem;"><b>{{i}}</b></span>
                          <span v-else>{{i}}</span>
                          <md-tooltip>Seite {{i}}</md-tooltip>
                        </md-button>
                      </div>
                    </div>
                    <md-content class="md-scrollbar md-card" id="pdfContentContainerId">
                      <md-progress-bar md-mode="indeterminate" v-if="sending"/>
                      <pdf v-if="previewPdf.url" :page="pdfPage" :resize="false" :scale.sync="pdfScale" :src="previewPdf.url" @numpages="setPdfNumPages"
                           class="pdf-frame">
                      </pdf>
                    </md-content>
                  </div>
                  <div v-else style="padding-top: 1rem;">
                    <h5><i>Keine Kinder für Vorschau verfügbar.</i></h5>
                  </div>
                </md-tab>

                <md-tab id="tab-template-settings" md-label="Einstellungen">
                  <div v-if="selectedTemplate" class="grid-x grid-padding-x" style="padding-top: 1rem;">
                    <div class="cell large-6 medium-12 small-12">
                      <md-card style="box-shadow: none">
                        <md-card-header>
                          <label style="font-size: 1rem">Ränder</label>
                        </md-card-header>
                        <md-card-content>
                          <div v-if="selectedTemplate.printMargins" class="grid-x grid-padding-x">
                            <div class="cell small-12 medium-4"></div>
                            <div class="cell small-12 medium-4">
                              <md-field :class="getValidationClass('top')">
                                <label>Oben (mm)
                                  <md-tooltip>Angabe in Millimeter (mm)</md-tooltip>
                                </label>
                                <md-input name="top" id="top" autocomplete="off" type="number"
                                          v-model="selectedTemplate.printMargins.top"
                                          :disabled="sending" @keyup="onTextChange"/>
                                <span class="md-error" v-if="!$v.selectedTemplate.printMargins.top.required">Druckrand oben ist notwendig</span>
                              </md-field>
                            </div>
                            <div class="cell small-12 medium-4"></div>
                            <div class="cell small-12 medium-4">
                              <md-field :class="getValidationClass('left')">
                                <label>Links (mm)
                                  <md-tooltip>Angabe in Millimeter (mm)</md-tooltip>
                                </label>
                                <md-input name="left" id="left" autocomplete="off" type="number"
                                          v-model="selectedTemplate.printMargins.left"
                                          :disabled="sending"/>
                                <span class="md-error" v-if="!$v.selectedTemplate.printMargins.left.required">Druckrand links ist notwendig</span>
                              </md-field>
                            </div>
                            <div class="cell small-12 medium-4"></div>
                            <div class="cell small-12 medium-4">
                              <md-field :class="getValidationClass('right')">
                                <label>Rechts (mm)
                                  <md-tooltip>Angabe in Millimeter (mm)</md-tooltip>
                                </label>
                                <md-input name="right" id="right" autocomplete="off" type="number"
                                          v-model="selectedTemplate.printMargins.right"
                                          :disabled="sending"/>
                                <span class="md-error" v-if="!$v.selectedTemplate.printMargins.right.required">Druckrand rechts ist notwendig</span>
                              </md-field>
                            </div>
                            <div class="cell small-12 medium-4"></div>
                            <div class="cell small-12 medium-4">
                              <md-field :class="getValidationClass('bottom')">
                                <label>Unten (mm)
                                  <md-tooltip>Angabe in Millimeter (mm)</md-tooltip>
                                </label>
                                <md-input name="bottom" id="bottom" autocomplete="off" type="number"
                                          v-model="selectedTemplate.printMargins.bottom"
                                          :disabled="sending"/>
                                <span class="md-error" v-if="!$v.selectedTemplate.printMargins.bottom.required">Druckrand unten ist notwendig</span>
                              </md-field>

                            </div>
                            <div class="cell hide-for-small-only medium-4"></div>
                          </div>
                        </md-card-content>
                      </md-card>
                    </div>
                    <div class="cell large-6 medium-12 small-12">
                      <div class="grid-x grid-padding-x">
                        <div class="cell small-8">
                          <md-field>
                            <label>Schriftart</label>
                            <md-select :disabled="sending" v-model="selectedTemplate.fonttype">
                              <md-option v-for="type of labels.fontTypes" :key="type" :value="type">
                                {{labels.fontType[type]}}
                              </md-option>
                            </md-select>
                            <md-tooltip>Kind für die Vorschau des Dokuments auswählen</md-tooltip>
                          </md-field>
                        </div>
                        <div class="cell small-4">
                          <md-field :class="getValidationClass('fontsize')">
                            <label>Schriftgröße
                              <md-tooltip>Schriftgröße für normale Formattierung</md-tooltip>
                            </label>
                            <md-input name="top" id="fontsize" autocomplete="off" type="number"
                                      v-model="selectedTemplate.fontsize"
                                      :disabled="sending" @keyup="onTextChange"/>
                            <span class="md-error" v-if="!$v.selectedTemplate.fontsize.required">Schriftgröße ist notwendig</span>
                          </md-field>
                        </div>
                      </div>
                      <div class="grid-x grid-padding-x">
                        <div class="cell">
                          <md-switch class="md-primary" v-model="selectedTemplate.showPageNumbers">
                            <p>Seitennummerierung</p>
                            <md-tooltip>Seitennummerierung <span v-if="selectedTemplate.showPageNumbers">de</span>aktivieren
                            </md-tooltip>
                          </md-switch>
                        </div>
                        <div class="cell">
                          <md-field>
                            <label>Position</label>
                            <md-select :disabled="sending || !selectedTemplate.showPageNumbers" v-model="selectedTemplate.pageNumbersPosition">
                              <md-option v-for="pos of labels.positions" :key="pos" :value="pos">
                                {{labels.position[pos]}}
                              </md-option>
                            </md-select>
                            <md-tooltip>Positionierung der Seitennummerierung</md-tooltip>
                          </md-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </md-tab>
              </md-tabs>
            </md-card-content>
          </md-card>
        </div>
      </div>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmSaveChangedDataDialog">
        <div class="grid-x grid-padding-x grid-padding-y" v-if="lastSelectedTemplate">
          <md-dialog-title><i class="fi-print"></i>&nbsp;&nbsp;&nbsp;Änderungen an Druckvorlage bestätigen
          </md-dialog-title>
          <div class="cell">
            <md-content>Es wurden Änderungen an der Druckvorlage <span class="title">{{lastSelectedTemplate.name}}</span> durchgeführt. Sollen diese gespeichert werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <button :disabled="sending" @click="onUpdateTemplate" class="button success"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja,
              speichern
            </button>
            <button :disabled="sending" @click="confirmSaveChangedDataDialog = false"
                    class="button alert"
                    style="margin-left: 1rem;"
                    type="button">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button @click="confirmSaveChangedDataDialog = false" class="close-button" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteTemplateDialog">
        <div class="grid-x grid-padding-x grid-padding-y" v-if="selectedTemplate">
          <md-dialog-title>
            <i class="fi-print"></i>&nbsp;&nbsp;&nbsp;Druckvorlage der Einrichtung <span
              class="title">{{selectedTemplate.name}}</span> löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll die Druckvorlage <span class="title"><b>{{selectedTemplate.name}}</b></span> der
              Einrichtung <span
                  class="title">{{selectedFacilityName}}</span> wirklich gelöscht werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          </div>
          <div class="cell text-center">
            <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
          </div>
          <div class="cell text-center">
            <button :disabled="sending || !absolutelySure" @click="deleteTemplate" class="button alert">
              <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Druckvorlage jetzt löschen
            </button>
            <button :disabled="sending" @click="showDeleteTemplateDialog = false" class="button success"
                    style="margin-left: 1rem;" type="button">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button @click="showDeleteTemplateDialog = false" class="close-button" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteLetterPaperDialog">
        <div class="grid-x grid-padding-x grid-padding-y" v-if="selectedFacility">
          <md-dialog-title>
            <i class="fi-page-filled"></i>&nbsp;&nbsp;&nbsp;Briefpapier der Einrichtung <span
              class="title">{{selectedFacility.name}}</span> löschen
          </md-dialog-title>
          <div class="cell">
            <md-content>Soll das Briefpapier für <span class="title"><b>Seite {{selectedLetterPaperPage + 1}}</b></span> der
              Einrichtung <span
                  class="title">{{selectedFacilityName}}</span> wirklich gelöscht werden?
            </md-content>
            <br>
            <md-progress-bar md-mode="indeterminate" v-if="letterPaperSending || sending"/>
          </div>
          <div class="cell text-center">
            <button :disabled="letterPaperSending" @click="deleteLetterPaper" class="button alert"><i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja,
              Briefpapier jetzt löschen
            </button>
            <button :disabled="letterPaperSending" @click="showDeleteLetterPaperDialog = false" class="button success"
                    style="margin-left: 1rem;" type="button">
              <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
            </button>
          </div>
          <button @click="showDeleteLetterPaperDialog = false" class="close-button" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </md-dialog>

      <EditTemplateDialog :facilityReferenceNumber="selectedFacilityReferenceNumber"
                          :showDialog="showEditTemplateDialog"
                          :template="newTemplate" @addTemplateSuccess="addTemplateSuccess"
                          @closeEditTemplateDialog="showEditTemplateDialog = false"
                          mode="add"
                          ref="editTemplateDialogRef"></EditTemplateDialog>
    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="BASE" moduleFunction="Druckvorlagen"></NoModuleCard>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import jQuery from 'jquery';
  import Spinner from 'vue-simple-spinner';
  import vue2Dropzone from 'vue2-dropzone';
  import {Md5} from 'ts-md5/dist/md5';
  import NoFacilityCard from "../components/cards/NoFacilityCard";
  import FacilityService from '../services/FacilityService';
  import PdfService from '../services/PdfService';
  import InfoService from '../services/InfoService';
  import BinaryService from '../services/BinaryService';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import pdf from 'pdfvuer';
  import {VueEditor} from 'vue2-editor';
  import {validationMixin} from 'vuelidate';
  import {
    required,
  } from 'vuelidate/lib/validators';
  import EditTemplateDialog from '../components/dialogs/EditTemplateDialog';
  import ChildService from "../services/ChildService";
  import HttpInterceptor from "../services/HttpInterceptor";
  import NoModuleCard from '../components/cards/NoModuleCard';

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByName = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.name).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'FacilityTemplates',
    mixins: [validationMixin],

    components: {
      EditTemplateDialog,
      'vue-simple-spinner': Spinner,
      NoFacilityCard,
      NoModuleCard,
      pdf,
      VueEditor,
      vueDropzone: vue2Dropzone,
    },

    mounted() {
      this.initData();
      window.addEventListener('resize', (event) => {
        this.resizeDialogElements(event);
      }, { passive: true });

      HttpErrorHandler.maintainDarkMode(this);
    },

    beforeDestroy() {
      this.cleanPDF();
      this.cleanFirstLetterPaperPDF();
      this.cleanSecondLetterPaperPDF();
      this.initDatePickerSyncHandler(false);
    },

    data() {
      return {
        disabledFirstDay: date => {
          const day = date.getDate();
          return day !== 1;
        },

        absolutelySure: false,
        sending: false,
        letterPaperSending: false,
        Templates: [],
        searched: [],
        search: null,
        selected: null,
        selectedTemplate: null,
        selectedTemplateMd5Hash: null,
        lastSelectedTemplate: null,
        newTemplate: null,

        dialogMode: 'update',

        selectedRowId: 0,

        availableFacilities: [],
        availableFacilitiesMap: {},
        selectedFacilityReferenceNumber: null,
        selectedFacilityName: '',
        selectedFacility: null,

        initialTextChanged: false,

        confirmSaveChangedDataDialog: false,
        showDeleteTemplateDialog: false,
        showDeleteLetterPaperDialog: false,
        showEditTemplateDialog: false,
        invalid: false,

        selectedLetterPaperPage: 0,

        templateTags: [],
        filteredTemplateTags: [],
        selectedTemplateTag: null,

        quillEditorInstance: null,
        quillRange: null,

        pdfTemplateData: null,
        children: [],
        facilityChildren: [],
        selectedPreviewChildId: null,
        selectedPreviewChildDsgvoPersonId: null,
        selectedPreviewChild: null,

        previewDate: Vue.moment().format('DD.MM.YYYY'),

        pdfScale: 'page-width',
        pdfPage: 1,
        pdfNumPages: 0,

        previewPdf: {
          url: null,
          link: null,
          blob: null,
        },

        firstLetterPaperPdf: {
          url: null,
          link: null,
          blob: null,
        },

        secondLetterPaperPdf: {
          url: null,
          link: null,
          blob: null,
        },

        optionsPageOne: {
          url: '',
          maxFilesize: 1.0, // 1024 KB
          headers: {
            'accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
          },
          createImageThumbnails: false,
          thumbnailWidth: 250,
          thumbnailHeight: 50,
          previewTemplate: '<div class="dz-preview dz-file-preview"></div>',
          dictDefaultMessage: 'Papier Seite 1',
          acceptedFiles: '.pdf',
        },

        optionsPageTwo: {
          url: '',
          maxFilesize: 1.0, // 1024 KB
          headers: {
            'accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token')
          },
          createImageThumbnails: false,
          thumbnailWidth: 250,
          thumbnailHeight: 50,
          previewTemplate: '<div class="dz-preview dz-file-preview"></div>',
          dictDefaultMessage: 'Papier Seite 2',
          acceptedFiles: '.pdf',
        },

        uploadFileName: null,
        initialLetterPaperLoadSuccess: false,
        initialLetterPaperLoadFailure: false,
        previewTabActive: false,

        datePickerSyncHandlerInitialized: false,
      }
    },

    validations: {
      selectedTemplate: {
        name: {
          required,
        },
        type: {
          required,
        },
        printMargins: {
          top: {
            required,
          },
          left: {
            required,
          },
          right: {
            required,
          },
          bottom: {
            required,
          },
        },
        fontsize: {
          required,
        },
      },
    },

    methods: {

      initDatePickerSyncHandler(init) {
        if (init && !this.datePickerSyncHandlerInitialized) {
          setTimeout(() => {
            jQuery('#preview-date-dp').children('input').bind('blur', () => {
              this.previewDate = jQuery('#preview-date-dp').children('input').val();
              this.updatePreviewChild();
            })
          }, 500);
          this.datePickerSyncHandlerInitialized = true;
        } else {
          jQuery('#preview-date-dp').children('input').unbind('blur');
        }
      },

      initData() {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            this.restoreUserSettings();
            this.getTemplateTags();
            this.getAllOrganizationChildren();
            this.getAllOrganizationFacilities();
            clearInterval(reloadIntervalId);
          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      buildUploadUrl(facilityReferenceNumber) {
        this.optionsPageOne.url = '';
        this.optionsPageTwo.url = '';
        setTimeout(() => this.optionsPageOne.url = this.$store.getters.serverUrl + 'binary/letter/upload/' + facilityReferenceNumber + '/0', 100);
        setTimeout(() => this.optionsPageTwo.url = this.$store.getters.serverUrl + 'binary/letter/upload/' + facilityReferenceNumber + '/1', 100);
      },

      getAllOrganizationChildren() {
        this.sending = true;
        ChildService.getAllOrganizationChildren()
          .then(response => {
            this.children = response.data;
            this.sending = false;
            this.filterChildren();
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Kinder');
          })
      },

      getAllOrganizationFacilities() {
        this.availableFacilities = this.getAvailableFacilities;
        this.buildAvailableFacilities();
        this.refreshPaperLettersPreview();
        this.resizeDialogElements();
      },

      getTemplateTags() {
        this.sending = true;
        InfoService.getTemplateTags()
          .then(response => {
            this.sending = false;
            this.templateTags = [];
            Object.entries(response.data).forEach(entry => this.templateTags.push({
              key: entry[0],
              value: entry[1],
            }));
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Druckvorlagen Template Tags für Einrichtung ' + this.selectedFacilityName);
          })
      },

      filterTemplateTags() {
        if (this.templateTags && this.selectedTemplate) {
          this.filteredTemplateTags = [];
          for (let tag of this.templateTags) {
            if (tag.value.scopeOfUse.includes(this.selectedTemplate.type)) {
              let tooltip = '<b>Beschreibung:</b><br>' + tag.value.description + '<br><br>' +
                  '<b>Argumente:</b><br>' + tag.value.arguments + '<br><br>' +
                  '<b>Beispiel:</b><br>' + tag.value.example;
              this.filteredTemplateTags.push({
                key: tag.key,
                value: {
                  arguments: tag.value.arguments,
                  description: tag.value.description,
                  example: tag.value.example,
                  name: tag.value.name,
                  tooltip: tooltip,
                }
              });
            }
          }
        }
      },

      filterChildren() {
        if (this.children && this.selectedFacility) {
          this.facilityChildren = [];
          for (let child of this.children) {
            if (child.facilityReference === this.selectedFacility.referenceNumber) {
              this.facilityChildren.push(child);
            }
          }
          if (this.selectedPreviewChildId === null && this.facilityChildren.length > 0) {
            this.selectedPreviewChildId = this.facilityChildren[0].id;
            if (this.facilityChildren[0].relatedPersons.length > 0) {
              this.selectedPreviewChildDsgvoPersonId = this.facilityChildren[0].relatedPersons[0].id;
            }
          }
        }
        this.updatePreviewChild();
      },

      updatePreviewChild() {
        this.selectedPreviewChild = null;
        for (let child of this.children) {
          if (child.id === this.selectedPreviewChildId) {
            this.selectedPreviewChild = child;
            if (this.selectedPreviewChild.relatedPersons.length > 0) {
              this.selectedPreviewChildDsgvoPersonId = this.selectedPreviewChild.relatedPersons[0].id;
            }
          }
        }
        setTimeout(() => this.refreshPreview(), 100);
      },

      cleanPDF() {
        if (this.previewPdf.url) {
          this.previewPdf.url = window.URL.revokeObjectURL(this.previewPdf.url);
          this.previewPdf.blob = null;
        }
        this.previewPdf.url = null;
      },

      cleanFirstLetterPaperPDF() {
        if (this.firstLetterPaperPdf.url) {
          this.firstLetterPaperPdf.url = window.URL.revokeObjectURL(this.firstLetterPaperPdf.url);
          this.firstLetterPaperPdf.blob = null;
        }
        this.firstLetterPaperPdf.url = null;
      },

      cleanSecondLetterPaperPDF() {
        if (this.secondLetterPaperPdf.url) {
          this.secondLetterPaperPdf.url = window.URL.revokeObjectURL(this.secondLetterPaperPdf.url);
          this.secondLetterPaperPdf.blob = null;
        }
        this.secondLetterPaperPdf.url = null;
      },

      setPdfNumPages(numPages) {
        if (numPages !== this.pdfNumPages) {
          this.pdfPage = 1;
        }
        this.pdfNumPages = numPages;
      },

      onSelectPdfPage(page) {
        this.pdfPage = page;
      },

      createPreviewPdfForTemplate(referenceNumber, templateData, pdf) {
        this.sending = true;
        PdfService.createPdf(templateData)
          .then(response => {
            setTimeout(() => {
              pdf.blob = new Blob([response.data, 'utf-8'], {type: "application/pdf"});
              pdf.url = window.URL.createObjectURL(pdf.blob);

              if (pdf.link) {
                document.body.removeChild(pdf.link);
              }
              pdf.link = document.createElement('a');
              const id = 'print-template-preview-' + referenceNumber + '-' + templateData[0].template.type;
              pdf.link.href = pdf.url;
              pdf.link.setAttribute('download', id + '.pdf');
              pdf.link.setAttribute('id', id);
              document.body.appendChild(pdf.link);

              this.sending = false;

              setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
            }, 50);
          })
          .catch(e => {
            this.sending = false;
            if (this.selectedPreviewChild && this.selectedTemplate) {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen des PDF für die Voransicht der Druckvorlage <b>' + this.selectedTemplate.name + '</b> für Kind <b>' + this.selectedPreviewChild.masterdata.fullName + '</b> zum <b>' + this.previewDate + '</b>');
            } else {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen des PDF für die Voransicht der Druckvorlage <b>' + this.labels.templateType['BLANK'] + '</b>');
            }
          })
      },

      getTemplateData(referenceNumber, config, createPdf, pdf) {
        this.sending = true;
        PdfService.getTemplateData(config)
          .then(response => {
            this.pdfTemplateData = response.data;
            this.sending = false;

            if (createPdf) {
              this.createPreviewPdfForTemplate(referenceNumber, response.data, pdf)
            }
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Erzeugen der PDF Template Daten für Kind ' + this.selectedPreviewChild.masterdata.fullName + ' zur Erstellung der Vorschau zum ' + this.previewDate);
          })
      },

      prepareConfigForTemplateDataRequest(template) {
        let selectedYear = Number(this.previewDate.substr(6, 4));
        let selectedMonth = Number(this.previewDate.substr(3, 2));
        return [{
          facilityReference: this.selectedFacility.referenceNumber,
          childId: this.selectedPreviewChildId,
          dsgvoPersonId: this.selectedPreviewChildDsgvoPersonId,
          year: selectedYear,
          month: selectedMonth,
          template: template,
          preview: 'true',
        }]
      },

      onSelectTemplateType() {
        this.filterChildren();
        this.getTemplateData(this.selectedFacilityReferenceNumber, this.prepareConfigForTemplateDataRequest(this.selectedTemplate), false, this.previewPdf);
        this.filterTemplateTags();
      },

      onTabChanged(tabId) {
        this.previewTabActive = false;
        if (tabId === 'tab-template-preview' ) {
          this.previewTabActive = true;
          this.refreshPreview();
        }

        if (tabId === 'tab-template-editor' ) {
          jQuery('.ql-link').remove();
          jQuery('.ql-image').remove();
          jQuery('.ql-video').remove();
          jQuery('.ql-clean').remove();
        }
      },

      refreshPreview() {
        if (this.selectedTemplate && this.previewTabActive) {
          this.cleanPDF();
          this.getTemplateData(this.selectedFacilityReferenceNumber, this.prepareConfigForTemplateDataRequest(this.selectedTemplate), true, this.previewPdf)
        }
      },

      onInsertTemplateTag() {
        if (this.quillEditorInstance && this.quillRange && this.selectedTemplateTag) {
          this.quillEditorInstance.insertText(this.quillRange.index, this.selectedTemplateTag);
        }

        setTimeout(() => this.selectedTemplateTag = '', 250);
      },

      restoreUserSettings() {
        this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
        if (this.selectedFacilityReferenceNumber === '*') {
          this.selectedFacilityReferenceNumber = null;
        }
        if (this.facility) {
          this.selectedFacilityReferenceNumber = this.facility.referenceNumber;
          this.$store.commit('selectedFacility', null);
        }
      },

      onTouchOverlay() {
      },

      pristine() {
        return (this.selectedTemplateMd5Hash === Md5.hashAsciiStr(JSON.stringify(this.selectedTemplate)));
      },

      onTextChange() {
        if (!this.initialTextChanged) {
          this.initialTextChanged = true;
          // Reset only once after template selection
          this.selectedTemplateMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedTemplate));
        }
      },

      onFocusEditor(quill) {
        this.quillEditorInstance = quill;

        let range = quill.getSelection();
        if (range) {
          this.quillRange = range;
        }
      },

      onSelectionChange(range) {
        if (range) {
          this.quillRange = range;
        }
      },

      resizeDialogElements() {
        setTimeout(() => {
          jQuery('#quill-container').height(jQuery('#editTemplateCardId').height() -
            jQuery('#editTemplateCardHeaderId').height() - 32 - jQuery('.ql-toolbar').height() - 12 - 184);
          jQuery('#pdfContentContainerId').height(jQuery('#editTemplateCardId').height() -
            jQuery('#editTemplateCardHeaderId').height() -
            jQuery('.md-tabs-navigation').first().height() -
            jQuery('#previewSettingsId').height() - 80);
          jQuery('.md-content.md-table-content.md-scrollbar').attr('style', 'height: 1100px');
        }, 100);
      },

      searchOnTable() {
        if (this.selectedFacility) {
          this.searched = this.selectedFacility.facilitySettings.templates;
          this.searched.forEach((item, index) => {
            item.id = index;
          });
          this.searched = searchByName(this.searched, this.search);
        }
      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),

      getValidationClass(fieldName) {
        let field = this.$v.selectedTemplate[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      buildAvailableFacilities() {
        for (let i = 0; i < this.availableFacilities.length; i++) {
          if (this.availableFacilities[i].referenceNumber === '*') {
            this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
          }
        }
        if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
          this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
          this.selectedFacilityName = this.availableFacilities[0].name;
        }

        this.onSelectFacility();
      },

      refreshPaperLettersPreview() {
        if (this.selectedFacility) {

          if (this.selectedFacility.facilitySettings.letterPaperFirstId) {
            let config = [{
              facilityReference: this.selectedFacility.referenceNumber,
              childId: '',
              dsgvoPersonId: '',
              template: {
                type: 'BLANK',
                content: '',
                name: 'Leere Seite',
                standard: false,
                printMargins: {
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0
                },
                forceUseOfLetterPaper: 0,
              },
              year: 0,
              month: 0,
              preview: true,
            }];
            this.cleanFirstLetterPaperPDF();
            this.getTemplateData(this.selectedFacilityReferenceNumber, config, true, this.firstLetterPaperPdf)
            window.dispatchEvent(new Event('resize'));
          }
          if (this.selectedFacility.facilitySettings.letterPaperSecondId) {
            let config = [{
              facilityReference: this.selectedFacility.referenceNumber,
              childId: '',
              dsgvoPersonId: '',
              template: {
                type: 'BLANK',
                content: '',
                name: 'Leere Seite',
                standard: false,
                printMargins: {
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0
                },
                forceUseOfLetterPaper: 1,
              },
              year: 0,
              month: 0,
              preview: true,
            }];
            this.cleanSecondLetterPaperPDF();
            this.getTemplateData(this.selectedFacilityReferenceNumber, config, true, this.secondLetterPaperPdf)
            window.dispatchEvent(new Event('resize'));
          }
        }
      },

      onSelectFacility() {
        if (this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

          localStorage.setItem(this.user.md5 + '@selectedFacilityReferenceNumber', this.selectedFacilityReferenceNumber);

          this.selectedFacilityName = '';
          for (let facility of this.availableFacilities) {
            if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
              this.selectedFacilityName = facility.name;
              this.selectedFacility = facility;
            }
          }

          this.buildUploadUrl(this.selectedFacilityReferenceNumber);
          this.refreshPaperLettersPreview();

          this.onSelect(null);
          this.searchOnTable();
          this.filterTemplateTags();
          this.selectedPreviewChildId = null;
          this.selectedPreviewChildDsgvoPersonId = null;
          this.filterChildren();
        }
      },

      onSaveChanges() {
        this.validateTemplates();
        if (!this.invalid && this.dialogMode === 'update') {
          this.lastSelectedTemplate = this.selectedTemplate;
          this.onConfirmSaveChanges();
        }
      },

      onConfirmSaveChanges() {
        this.confirmSaveChangedDataDialog = true;
      },

      onDeleteLetterPaper(page) {
        if (this.selectedFacility) {
          this.selectedLetterPaperPage = page;
          this.showDeleteLetterPaperDialog = true;
        }
      },

      deleteLetterPaper() {
        if (this.selectedFacility) {

          this.letterPaperSending = true;
          BinaryService.deleteLetterPaper(this.selectedFacility.referenceNumber, this.selectedLetterPaperPage)
            .then(() => {
              this.letterPaperSending = false;
              this.showDeleteLetterPaperDialog = false;
              this.$store.commit('successMsg', 'Das Briefpapier für Seite ' + (this.selectedLetterPaperPage + 1) + ' der Einrichtung <b>' + this.selectedFacility.name + '</b> wurde erfolgreich gelöscht.');

              if (this.selectedLetterPaperPage === 0) {
                this.selectedFacility.facilitySettings.letterPaperFirstId = null;
                this.cleanFirstLetterPaperPDF();
              }
              if (this.selectedLetterPaperPage === 1) {
                this.selectedFacility.facilitySettings.letterPaperSecondId = null;
                this.cleanSecondLetterPaperPDF();
              }

              this.refreshPreview();
            })
            .catch(e => {
              this.letterPaperSending = false;
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Briefpapiers für Seite ' + (this.selectedLetterPaperPage + 1) + ' der Einrichtung <b>' + this.selectedFacility.name + '</b>');
            })
        }
      },

      onUpdateTemplate() {
        this.selectedFacility.facilitySettings.templates[this.lastSelectedTemplate.id] = this.lastSelectedTemplate;
        this.updateTemplate(this.lastSelectedTemplate);
      },

      updateTemplate(template) {
        this.sending = true;
        this.success = false;
        FacilityService.updateTemplate(this.selectedFacility.referenceNumber, template)
          .then((response) => {
            this.success = true;
            this.sending = false;
            this.$refs.editTemplateDialogRef.sending = false;

            let i = 0;
            for (let facility of this.availableFacilities) {
              if (facility.referenceNumber === this.selectedFacility.referenceNumber) {
                this.availableFacilities[i].facilitySettings = response.data;
                this.selectedFacility = this.availableFacilities[i];
              }
              i++;
            }

            if (this.confirmSaveChangedDataDialog) {
              this.confirmSaveChangedDataDialog = false;
              this.$store.commit('successMsg', 'Die Änderung an der Druckvorlage wurde erfolgreich gespeichert.');
              setTimeout(() => jQuery('#' + this.selectedTemplate.id).trigger('click'), 50);
            }

            if (this.showEditTemplateDialog) {
              this.showEditTemplateDialog = false;
              this.$store.commit('successMsg', 'Die Druckvorlage <b>' + template.name + '</b> wurde erfolgreich angelegt.');
              this.selectedTemplate = this.selectedFacility.facilitySettings.templates[this.selectedFacility.facilitySettings.templates.length - 1];

              setTimeout(() => {
                jQuery('#' + this.selectedTemplate.id).trigger('click')
                let list = jQuery('#template-table-id').children().first().next().next();
                list.animate({scrollTop: list.children('table').height()}, 'slow');
              }, 150);

            }

            this.searchOnTable();

            this.selectedTemplateMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedTemplate));
          })
          .catch(e => {
            this.sending = false;
            this.$refs.editTemplateDialogRef.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Speichern der Druckvorlage ' + template.name);
          })
      },

      onToggleFirstForAll() {
        this.updateFacility(this.selectedFacility);
      },

      updateFacility(facility) {
        this.sending = true;
        this.success = false;
        FacilityService.updateFacility(facility)
          .then(() => {
            this.success = true;
            this.sending = false;
            this.selectedTemplateMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedTemplate));
            this.refreshPreview();
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Aktualisieren der Einstellungen für Einrichtung ' + facility.name);
          })
      },

      validateTemplates() {
        this.$v.$touch();
        this.invalid = this.$v.$invalid;
      },

      onSelect(item) {
        if (this.selectedTemplateMd5Hash && !this.pristine() &&
            this.selectedTemplate.type !== 'EXAMPLE' && !this.selectedTemplate.standard) {
          this.onConfirmSaveChanges();
        }
        this.lastSelectedTemplate = JSON.parse(JSON.stringify(this.selectedTemplate));
        this.pdfPage = 1;

        if (item && item.id >= 0) {
          this.dialogMode = 'update';

          this.selectedRowId = item.id;
          this.selected = JSON.parse(JSON.stringify(item));
          this.selectedTemplate = JSON.parse(JSON.stringify(this.selected));
          this.selectedTemplateMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedTemplate));
          this.initialTextChanged = false;
          this.resizeDialogElements();
          this.filterTemplateTags();
          this.initDatePickerSyncHandler(true);
        } else {
          this.selected = null;
          this.selectedTemplate = null;
          this.selectedTemplateMd5Hash = null;
        }
      },

      onDeleteTemplate() {
        if (this.selected) {
          this.absolutelySure = false,
          this.showDeleteTemplateDialog = true;
        }
      },

      deleteTemplate() {
        this.sending = true;
        this.success = false;
        FacilityService.deleteTemplate(this.selectedFacility.referenceNumber, this.selectedTemplate)
          .then((response) => {
            this.success = true;
            this.sending = false;

            let i = 0;
            for (let facility of this.availableFacilities) {
              if (facility.referenceNumber === this.selectedFacility.referenceNumber) {
                this.availableFacilities[i].facilitySettings = response.data;
                this.selectedFacility = this.availableFacilities[i];
              }
              i++;
            }
            this.searchOnTable();

            this.selectedTemplateMd5Hash = Md5.hashAsciiStr(JSON.stringify(this.selectedTemplate));
            this.showDeleteTemplateDialog = false;
            this.$store.commit('successMsg', 'Die Druckvorlage <b>' + this.selectedTemplate.name + '</b> wurde erfolgreich gelöscht.');
          })
          .catch(e => {
            this.sending = false;
            HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Druckvorlage ' + this.selectedTemplate.name);
          })
      },

      onAddTemplate() {
        this.dialogMode = 'add';

        if (this.selectedTemplateMd5Hash && !this.pristine() &&
            this.selectedTemplate.type !== 'EXAMPLE' && !this.selectedTemplate.standard) {
          this.onSaveChanges();
          return;
        }
        this.addTemplate();
      },

      addTemplate() {
        this.newTemplate = JSON.parse(JSON.stringify({
          type: "",
          name: "",
          content: "<p>Bitte hier den Inhalt erstellen</p>",
          standard: false,
          active: true
        }));
        this.showEditTemplateDialog = true;
      },

      addTemplateSuccess(newTemplate) {
        if (!this.selectedFacility.facilitySettings.templates.find(element => element.name === newTemplate.name)) {
          this.updateTemplate(newTemplate);
        } else {
          this.$store.commit('errorMsg', 'Der Name <b>' + newTemplate.name + '</b> für die neue Druckvorlage existiert bereits.');
          this.$refs.editTemplateDialogRef.sending = false;
        }
      },

      onVdropzoneMounted() {
        jQuery('#letterPaperFileDropzonePage1').removeClass().addClass('button select-letter-paper-button');
        jQuery('#letterPaperFileDropzonePage2').removeClass().addClass('button select-letter-paper-button');
      },

      onVdropzoneProcessing(file) {
        HttpInterceptor.doRefreshToken();
        this.optionsPageOne.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
        this.optionsPageTwo.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
        this.letterPaperSending = file.processing;
        this.uploadFileName = file.upload.filename;
        this.initialLetterPaperLoadSuccess = false;
        this.initialLetterPaperLoadFailure = false;
      },

      onVdropzoneClick(page) {
        HttpInterceptor.doRefreshToken();
        this.optionsPageOne.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
        this.optionsPageTwo.headers.Authorization = 'Bearer ' + sessionStorage.getItem('sose.access_token');
        this.selectedLetterPaperPage = page;
      },

      onVdropzoneError(file, message) {
        this.initialLetterPaperLoadFailure = true;
        let errMsg = 'Fehler beim Lesen der PDF-Datei: <b>' + file.upload.filename + '</b><br>';
        if (message.status) {
          errMsg += '<br><b>' + message.status + '</b>';
        }
        if (message.error) {
          errMsg += ' ' + message.error;
        }
        if (message.message) {
          errMsg += '<br>' + message.message;
        }

        this.letterPaperSending = false;
        HttpErrorHandler.handleError(null, this, errMsg);

        if (message.error === 'invalid_token') {
          HttpInterceptor.forceLogout();
        }
      },

      onVdropzoneSuccess(file, response) {
        this.letterPaperSending = false;
        this.selectedFacility.facilitySettings = response;
        this.$store.commit('successMsg', 'Die PDF-Datei <b>' + file.upload.filename + '</b> wurde erfolgreich eingelesen.');
        this.initialLetterPaperLoadSuccess = true;
        this.refreshPaperLettersPreview();
        this.refreshPreview();
      },
    },

    computed: {
      dataAvailable() {
        return this.isAuthenticated && this.organization &&
            this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
      },

      facilitiesNotExists() {
        return this.organization && this.organization.facilities && this.organization.facilities.length <= 0;
      },

      facility() {
        return this.$store.getters.selectedFacility;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isFacilityAdmin() {
        return this.$store.getters.facilityAdmin;
      },

      isManagement() {
        return this.$store.getters.management;
      },

      isTreasurer() {
        return this.$store.getters.treasurer;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      canWrite() {
        return this.$store.getters.canWrite;
      },

      labels() {
        return this.$store.getters.labels;
      },

      user() {
        return this.$store.getters.user;
      },

      organization() {
        return this.$store.getters.organization;
      },

      getAvailableFacilities() {
        return this.$store.getters.availableFacilities;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .md-dialog /deep/.md-dialog-container {
    max-width: 800px;
  }

  h5 {
    font-weight: 400;
    color: lightseagreen;
  }

  .title {
    color: chocolate;
    font-weight: bold;
    font-size: larger;
  }

  .title-thin {
    color: cornflowerblue;
    font-weight: lighter;
    font-size: larger;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    max-width: 330px;
    white-space: normal;
  }

  .pdf-frame {
    width: 100%;
    margin: 0 auto;
  }

  .md-content {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    overflow: auto;
  }

  .letter-paper-container {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .letter-paper {
    width: 210px;
    height: 300px;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .letter-paper-button {
    border-radius: 3px;
    max-width: 200px;
    max-height: 200px;
    margin: 8px;
    display: flex;
    align-items: baseline;
    justify-content: left;
  }
  
  .select-letter-paper-button {
    margin: 0;
  }

  .md-tab {
    padding: 0;
  }
</style>