<template>
  <div v-if="template">
    <md-dialog :md-click-outside-to-close="false" id="createNewTemplateDialog" :md-active.sync="dialogVisible" @md-closed="closeDialog">

      <md-dialog-title>

        <form novalidate @submit.prevent="validateTemplate">

          <div class="grid-x grid-padding-x">
            <div v-if="mode === 'up date'" class="cell large-5 medium-5">
              <i class="fi-pencil"></i>&nbsp;&nbsp;&nbsp;Druckvorlage bearbeiten
            </div>
            <div v-else-if="mode === 'add'" class="cell large-5 medium-5">
              <i class="fi-print"></i>&nbsp;&nbsp;&nbsp;Neue Druckvorlage hinzufügen
            </div>
            <div class="cell large-2 medium-2" style="margin-top: -5px;">
              <SessionTime></SessionTime>
            </div>
            <div class="cell large-5 medium-5 text-center">
              <button v-if="mode === 'update'" class="button success" type="submit" :disabled="sending">
                <i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Änderungen speichern
              </button>
              <button v-else-if="mode === 'add'" class="button success" type="submit" :disabled="sending">
                <i class="fi-price-tag"></i>&nbsp;&nbsp;Hinzufügen
              </button>
              <button class="button alert" style="margin-left: 1rem;" @click="closeDialog" type="button"
                      :disabled="sending">
                <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
              </button>
            </div>
          </div>

        </form>

      </md-dialog-title>

      <md-dialog-content id="dialogContentId">
        <TemplateForm v-if="template" ref="templateForm" :template="template" :sending="sending" :mode="mode">
        </TemplateForm>

        <md-progress-bar md-mode="indeterminate" v-if="sending"/>

      </md-dialog-content>

      <button class="close-button" type="button" @click="closeDialog">
        <span aria-hidden="true">&times;</span>
      </button>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmMissingTemplateDataDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <i class="fi-pricetag"></i>&nbsp;&nbsp;&nbsp;Unvollständigen
          Datensatz für Druckvorlage bestätigen
        </md-dialog-title>
        <div class="cell">
          <md-content>Soll der unvollständige Datensatz wirklich gespeichert werden?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <div @click="confirmProcessingData" class="button success"><i class="fi-save"></i>&nbsp;&nbsp;&nbsp;Ja,
            speichern
          </div>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="closeConfirmationDialog"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="closeConfirmationDialog">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>
</template>

<script>

  import jQuery from 'jquery';
  import TemplateForm from '../forms/TemplateForm';
  import Spinner from 'vue-simple-spinner'
  import SessionTime from '../animations/SessionTime';

  export default {

    name: 'EditTemplateDialog',

    components: {
      TemplateForm,
      'vue-simple-spinner': Spinner,
      SessionTime,
    },

    watch: {
      showDialog() {
        this.dialogVisible = this.showDialog;
        this.sending = false;
      },
    },

    props: {
      template: {
        required: true
      },
      facilityReferenceNumber: {
        required: true
      },
      showDialog: {
        required: true
      },
      mode: {
        required: true
      }
    },

    data: () => ({
      success: true,
      sending: false,
      confirmMissingTemplateDataDialog: false,
      dialogVisible: false,
    }),

    methods: {

      addTemplate() {
        this.sending = true;
        this.$emit("addTemplateSuccess", this.template);
      },

      confirmMissingData() {
        jQuery('#createNewTemplateDialog').attr("style", "display:none");
        this.confirmMissingTemplateDataDialog = true;
      },

      closeConfirmationDialog() {
        this.confirmMissingTemplateDataDialog = false;
        jQuery('#createNewTemplateDialog').attr("style", "display:normal");
      },

      confirmProcessingData() {
        this.closeConfirmationDialog();
        if (this.mode === 'add') {
          this.addTemplate();
        }
      },

      validateTemplate() {
        this.$refs.templateForm.validateTemplate();

        if (!this.$refs.templateForm.invalid) {
          if (this.mode === 'add') {
            this.addTemplate();
          }
        }
      },

      closeDialog() {
        this.$emit("closeEditTemplateDialog");
      }
    },

    computed: {

      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      isAdmin() {
        return this.$store.getters.admin
      },

      isSage() {
        return this.$store.getters.sage
      },

      organization() {
        return this.$store.getters.organization
      },

      labels() {
        return this.$store.getters.labels
      },
    },
  };
</script>

<style scoped>
  .md-dialog /deep/.md-dialog-container {
    min-width: 900px;
  }

  h5 {
    font-weight: 400;
    color: lightseagreen;
  }
</style>
